@font-face {
  font-display: block;
  font-family: Poison;
  src: local(Poison),
    url(../poison/Poison-Regular.otf) format(opentype) tech(color-COLRv1), url(../poison/Poison-Regular.ttf) format(opentype);
}

* {
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Poison';
  background-color: #0f0f0f;
}


.i-scroll{color: black;}
.i-scroll:hover{color: white;}

.d--none{display: none;}


/* .progress[data-bg=a]{
    color:white;
    background-color: white;
}
.progress[data-bg=a]:hover{
    background-color: rgb(238, 136, 42);
} */

/* progress[value] {
    --color: blue; 
    --background: lightgrey; 
   the progress color 
   the background color  
    appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
    width: 200px;
    margin: 0 10px;
    border-radius: 10em;
    background: var(--background);
  }
  progress[value]::-webkit-progress-bar {
    border-radius: 10em;
    background: var(--background);
  }
  progress[value]::-webkit-progress-value {
    border-radius: 10em;
    background: var(--color);
  }
  progress[value]::-moz-progress-bar {
    border-radius: 10em;
    background: var(--color);
  } */

input[type=range] {
  appearance: none;
  /* Hides the slider so that custom slider can be made */
  /* width: 100%; */
  /* Specific width is required for Firefox. */
  background: transparent;
  /* Otherwise white in Chrome */
  flex-grow: 1;
}

input[type=range]::-webkit-slider-thumb {
  appearance: none;
}

input[type=range]:focus {
  outline: none;
  /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
}

input[type=range]::-ms-track {
  width: 100%;
  cursor: pointer;

  /* Hides the slider so custom styles can be added */
  background: transparent;
  border-color: transparent;
  color: transparent;
}





/* Special styling for WebKit/Blink */
input[type=range]::-webkit-slider-thumb {
  appearance: none;
  height: 4px;
  width: 1.5em;
  border-radius: 23px;
  background: rgb(238, 136, 42);
  cursor: pointer;
  margin-top: 0px;
  /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
  /* box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d; Add cool effects to your sliders! */
}

input[type=range]::-webkit-slider-thumb:hover {
  appearance: none;
  height: 18px;
  width: 1.5em;
  border-radius: 23px;
  background: #ffffff;
  cursor: pointer;
  margin-top: -6px;
  /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
  /* box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d; Add cool effects to your sliders! */
}

/* All the same stuff for Firefox */
input[type=range]::-moz-range-thumb {
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  border: 1px solid #000000;
  height: 36px;
  width: 16px;
  border-radius: 3px;
  background: #ffffff;
  cursor: pointer;
}

/* All the same stuff for IE */
input[type=range]::-ms-thumb {
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  border: 1px solid #000000;
  height: 36px;
  width: 16px;
  border-radius: 3px;
  background: #ffffff;
  cursor: pointer;
}




input[type=range]::-webkit-slider-runnable-track {
  /* width: 100%; */
  height: 4px;
  cursor: pointer;
  background: #c6c6c6;
  border-radius: 15px;
}



/* input[type=range]:focus::-webkit-slider-runnable-track:hover{
  background: rgb(238, 136, 42);
} */

/* input[type=range]:focus{
  background: rgb(238, 136, 42);
}

input[type=range]:focus:hover{
  background: rgb(238, 136, 42);
} */

input[type=range]::-moz-range-track {
  width: 100%;
  height: 8.4px;
  cursor: pointer;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  background: #3071a9;
  border-radius: 1.3px;
  border: 0.2px solid #010101;
}

input[type=range]::-ms-track {
  width: 100%;
  height: 8.4px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  border-width: 16px 0;
  color: transparent;
}

input[type=range]::-ms-fill-lower {
  background: #2a6495;
  border: 0.2px solid #010101;
  border-radius: 2.6px;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
}

input[type=range]:focus::-ms-fill-lower {
  background: #3071a9;
}

input[type=range]::-ms-fill-upper {
  background: #3071a9;
  border: 0.2px solid #010101;
  border-radius: 2.6px;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
}

input[type=range]:focus::-ms-fill-upper {
  background: #367ebd;
}


.header {
  display: grid;
  background-color: transparent;
  padding: 15px 15px 15px 15px;
  grid-template-rows: 39px;
  grid-template-columns: 1fr;
  grid-template-areas: "ch1";
  position: fixed;
  top: 0px;
  right: 0px;
  left: 0px;
}

.headerA5{display: none;}

.headerChild1 {
  display: flex;
  align-items: center;
  justify-content: left;
  grid-area: ch1;
  font-size: 15px
}

.headerChild1-e1 {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  text-align: center;
  font-size: 15px;
  flex-direction: column;
  background: #000;
}

.hover-opacity-1{
    margin-top: 2px;
    max-width:40px;height:40px;border:1px solid transparent;
    border-radius:40px;border-width:2px 2px 2px 2px;border-color:white
}

.hover-opacity-1:hover{opacity: 0.7;}


.hover-opacity-2{
  margin-top: 2px;
  max-width:40px;height:40px;border:1px solid transparent;
  border-radius:40px;border-width:2px 2px 2px 2px;border-color:white
}

.hover-opacity-2:hover{opacity: 0.7;}

.headerChild1-e2 {
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  text-align: center;
  font-size: 15px;
  flex-direction: column;
  background: #000;
  z-index: 75;
}

.fz{display:none;font-size: 35px; cursor: pointer;}
.headerChild2 {
  grid-area: ch2;
  /* margin-top: -6px; */
}

/* .headerChild2-a {
  display: none;
  grid-area: ch2;
  margin-top: -6px;
} */

.headerChild3 {
  display: none;
  grid-area: ch3
}

/* .headerChild3-a {
  display: none;
  grid-area: ch3
} */

.linkStyle1 {
  color: white;
  text-decoration: none
}

.linkStyle2 {
  display: block;
  color: white;
  text-decoration: none
}

.listStyle1 {
  display: inline-flex;
  list-style: none
}

.listStyle1-1 {
  list-style: none;
}



.grid1 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 600px 600px;
  grid-template-areas: 'ch1 ch2' 'ch3 ch4';
  gap: 0;
  margin-top: 75px
}

.grid1Child1 {
  grid-area: ch1;
  background-image: url(/public/images/PRODUCTION2.png);
  background-position: center;
}

.grid1Child2 {
  grid-area: ch2;
  background-image: url(/public/images/MUSIC.png);
  background-position: center;
}

.grid1Child3 {
  grid-area: ch3;
  background-image: url(/public/images/COMMUNICATION.png);
  background-position: center;
}

.grid1Child4 {
  grid-area: ch4;
  background-image: url(/public/images/HGM.png);
  background-position: center;
}

.grid2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 600px 600px;
  grid-template-areas: 'ch1 ch2' 'ch3 ch4';
  gap: 0;
}

.grid2Child1 {
  grid-area: ch1;
  background-image: url(/public/images/CONTENU_DU_SITE28.png);
  background-position: center;
  background-size: cover
}

.grid2Child2 {
  grid-area: ch2;
  background-image: url(/public/images/CONTENU_DU_SITE31.png);
  background-position: center;
  background-size: cover
}

.grid2Child3 {
  grid-area: ch3;
  background-image: url(/public/images/CONTENU_DU_SITE35.png);
  background-position: center;
  background-size: cover
}

.grid2Child4 {
  grid-area: ch4;
  background-image: url(/public/images/CONTENU_DU_SITE38.png);
  background-position: center;
  background-size: cover
}

.grid2Card {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 600px;
  grid-template-areas: 'ch3 ch4';
  gap: 0;
}

.grid2CardChild1 {
  grid-area: ch3;
  background-image: url(/public/images/HCMJ.png);
  background-position: center;
  background-size: cover
}

.grid2CardChild2 {
  grid-area: ch4;
  background-image: url(/public/images/rsmusic2.png);
  background-position: center;
  background-size: cover
}

.grid4Card {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 600px;
  grid-template-areas: 'ch3 ch4';
  gap: 0;
}

.grid4CardChild1 {
  grid-area: ch3;
  background-image: url(/public/images/rsmusic2.png);
  background-position: center;
  background-size: cover
}

.grid4CardChild2 {
  grid-area: ch4;
  background-image: url(/public/images/rscom-1.png);
  background-position: center;
  background-size: cover
}

.grid6Card {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 600px;
  grid-template-areas: 'ch3 ch4';
  gap: 0;
}

.grid6CardChild1 {
  grid-area: ch3;
  background-image: url(/public/images/rscom-1.png);
  background-position: center;
  background-size: cover
}

.grid6CardChild2 {
  grid-area: ch4;
  background-image: url(/public/images/PRODUCT2.png);
  background-position: center;
  background-size: cover
}

.f {
  display: flex;
  align-items: center;
  justify-content: center
}

.f1 {
  flex-grow: 1;
  text-align: center
}

.f2 {
  flex-grow: 10;
  overflow: hidden
}

.f3 {
  flex-grow: 1;
  text-align: center
}

.grid2Cs {
  display: grid;
  padding: 0px 0px 0px 0px;
  grid-template-columns: 1fr 1fr 1fr;
  margin-bottom: 30px;
  grid-template-rows: 450px 450px 450px;
  grid-template-areas: 'ch1 ch2 ch3' 'ch4 ch5 ch6' 'ch7 ch8';
  gap: 2rem
}

.grid2CsA {
  display: grid;
  color: white;
  padding: 0px 0px 0px 0px;
  grid-template-columns: 1fr 1fr 1fr;
  margin-bottom: 30px;
  grid-template-rows: 450px 450px 450px;
  grid-template-areas: 'ch1 ch2 ch3' 'ch4 ch5 ch6' 'ch7 ch8';
  gap: 2rem
}

.grid2CsB {
  display: grid;
  color: white;
  padding: 0px 0px 0px 0px;
  grid-template-columns: 1fr 1fr 1fr;
  margin-bottom: 30px;
  grid-template-rows: 450px 450px 450px;
  grid-template-areas: 'ch1 ch2 ch3' 'ch4 ch5 ch6' 'ch7 ch8';
  gap: 2rem
}

.grid2CsC {
  display: grid;
  color: white;
  padding: 0px 0px 0px 0px;
  grid-template-columns: 1fr 1fr 1fr;
  margin-bottom: 30px;
  grid-template-rows: 450px 450px 450px;
  grid-template-areas: 'ch1 ch2 ch3' 'ch4 ch5 ch6' 'ch7 ch8';
  gap: 2rem
}

.grid2CsChild1 {
  grid-area: ch1;
  background-image: url(/public/images/ga-1.png);
  background-position: center;
  background-size: cover
}

.grid2CsChild2 {
  grid-area: ch2;
  background-image: url(/public/images/ga-2.png);
  background-position: center;
  background-size: cover
}

.grid2CsChild3 {
  grid-area: ch3;
  background-image: url(/public/images/ga-3.png);
  background-position: center;
  background-size: cover
}

.grid2CsChild4 {
  grid-area: ch4;
  background-image: url(/public/images/ga-4.png);
  background-position: center;
  background-size: cover
}

.grid2CsChild5 {
  grid-area: ch5;
  background-image: url(/public/images/ga-5.png);
  background-position: center;
  background-size: cover
}

.grid2CsChild6 {
  grid-area: ch6;
  background-image: url(/public/images/ga-6.png);
  background-position: center;
  background-size: cover
}

.grid2CsChild7 {
  grid-area: ch7;
  background-image: url(/public/images/ga-7.png);
  background-position: center;
  background-size: cover
}

.grid2CsChild8 {
  grid-area: ch8;
  background-image: url(/public/images/ga-8.png);
  background-position: center;
  background-size: cover
}

.grid2A {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 50px 50px 50px 50px;
  grid-template-rows: 730px 730px;
  grid-template-areas: 'ch1 ch2' 'ch3 ch4';
  gap: 2.0rem
}

.grid2AChild1 {
  grid-area: ch1;
  background-image: url(/public/images/CONTENU_DU_SITE2.png);
  background-position: center;
  background-size: cover
}

.grid2AChild2 {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  grid-area: ch2
}

.grid2AChild3 {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  grid-area: ch3
}

.grid2AChild4 {
  grid-area: ch4;
  background-image: url(/public/images/CONTENU_DU_SITE1.png);
  background-position: center;
  background-size: cover
}

.grid3D {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 50px 50px 50px 50px;
  grid-template-rows: 730px 730px;
  grid-template-areas: 'ch1 ch2' 'ch3 ch4';
  gap: 2.0rem
}

.grid3DChild1 {
  grid-area: ch1;
  background-image: url(/public/images/CONTENU_DU_SITE22.png);
  background-position: center;
  background-size: cover
}

.grid3DChild2 {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  grid-area: ch2
}

.grid3DChild3 {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  grid-area: ch3
}

.grid3DChild4 {
  grid-area: ch4;
  background-image: url(/public/images/CONTENU_DU_SITE21.png);
  background-position: center;
  background-size: cover
}

.grid1D {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 50px 50px 50px 50px;
  grid-template-rows: 730px 730px 730px;
  grid-template-areas: 'ch1 ch2' 'ch3 ch4' 'ch5 ch6';
  gap: 2.0rem
}

.grid1DChild1 {
  grid-area: ch1;
  background-image: url(/public/images/rs-prod2.png);
  background-position: center;
  background-size: cover;
}

.grid1DChild2 {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  grid-area: ch2
}

.grid1DChild3 {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  grid-area: ch3
}

.grid1DChild4 {
  grid-area: ch4;
  background-image: url(/public/images/rs-prod3.png);
  background-position: center;
  background-size: cover
}

.grid1DChild5 {
  grid-area: ch5;
  background-image: url(/public/images/rs-prod4.png);
  background-position: center;
  background-size: cover
}

.grid1DChild6 {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  grid-area: ch6
}

/* grid1DChild7{display: flex;align-items:center;justify-content:center; */
/* background-color: transparent;grid-area: ch7} */
/* grid1DChild8{grid-area: ch8;background-image: url(/public/images/rs-prod5.png); */
/* background-position: center;background-size:cover} */
.grid2B {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 50px 50px 50px 50px;
  margin: 0px 0px 100px 0px;
  grid-template-rows: 730px 730px 730px;
  grid-template-areas: 'ch5 ch6' 'ch1 ch2' 'ch3 ch4';
  gap: 2.0rem
}

.grid2BChild1 {
  grid-area: ch1;
  background-image: url(/public/images/CONTENU_DU_SITE16.png);
  background-position: center;
  background-size: cover
}

.grid2BChild2 {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  grid-area: ch2
}

.grid2BChild3 {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  grid-area: ch3
}

.grid2BChild4 {
  grid-area: ch4;
  background-image: url(/public/images/CONTENU_DU_SITE14.png);
  background-position: center;
  background-size: cover
}

.grid2BChild5 {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  grid-area: ch5
}

.grid2BChild6 {
  grid-area: ch6;
  background-image: url(/public/images/CONTENU_DU_SITE13.png);
  background-position: center;
  background-size: cover
}

.grid9 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 50px 50px 50px 50px;
  grid-template-rows: 730px 410px;
  grid-template-areas: 'ch1 ch2' 'ch3 ch4';
  gap: 0;
}

.grid9Child1 {
  grid-area: ch1;
  background-image: url(/public/images/rs-prod5.png);
  background-position: center;
  background-size: cover
}

.grid9Child2 {
  grid-area: ch2;
  background-image: url(/public/images/CONTENUESITE.png);
  background-position: center;
  background-size: cover
}

.grid9Child3 {
  display: flex;
  margin-top: 50px;
  justify-items: center;
  background-color: transparent;
  grid-area: ch3
}

.grid9Child4 {
  display: flex;
  margin-top: 50px;
  justify-items: center;
  background-color: transparent;
  grid-area: ch4
}

.grid8A {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 50px 50px 50px 50px;
  grid-template-rows: 730px 410px;
  grid-template-areas: 'ch1 ch2' 'ch3 ch4';
  gap: 0;
}

.grid8AChild1 {
  grid-area: ch1;
  /* background-image: url(/public/images/new_1.jpg);
  background-position: center;
  background-size: cover */
}

.grid8AChild2 {
  grid-area: ch2;
  /* background-image: url(/public/images/new_2.jpg);
  background-position: center;
  background-size: cover */
}

.grid8AChild3 {
  display: flex;
  margin-top: 50px;
  justify-items: center;
  background-color: transparent;
  grid-area: ch3
}

.grid8AChild4 {
  display: flex;
  margin-top: 50px;
  justify-items: center;
  background-color: transparent;
  grid-area: ch4
}

.grid10 {
  display: grid;
  grid-template-columns: 1fr 4fr 1fr;
  /* margin: 90px 0px 90px 0px; */
  grid-template-rows: 410px;
  grid-template-areas: 'ch1 ch2 ch3';
  gap: 0;
}

.grid10Child1 {
  display: flex;
  margin-top: 50px;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  grid-area: ch1;
}

.grid10Child2 {
  display: flex;
  margin-top: 50px;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  grid-area: ch2
}

.grid10Child3 {
  display: flex;
  margin-top: 50px;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  grid-area: ch3
}

.grid10B {
  display: grid;
  grid-template-columns: 1fr;
  padding: 0px 0px 0px 0px;
  grid-template-areas: 'ch2';
  margin-bottom: 150px;
}

.grid10BChild1 {
  display: flex;
  margin-top: 50px;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  grid-area: ch1;
}

.grid10BChild2 {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  grid-area: ch2
}

.grid10BChild3 {
  display: flex;
  margin-top: 50px;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  grid-area: ch3
}

.grid11 {
  display: grid;
  grid-template-columns: 1fr 4fr 1fr;
  padding: 50px 50px 50px 50px;
  grid-template-rows: 410px;
  grid-template-areas: 'ch1 ch2 ch3';
  gap: 0;
}

.grid11Child1 {
  display: flex;
  margin-top: 50px;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  grid-area: ch1;
}

.grid11Child2 {
  display: flex;
  margin-top: 50px;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  grid-area: ch2
}

.grid11Child3 {
  display: flex;
  margin-top: 50px;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  grid-area: ch3
}

.grid3 {
  display: grid;
  align-items: center;
  justify-items: center;
  grid-template-columns: 1fr;
  grid-template-rows: 100px;
  grid-template-areas: 'ch1 ch1';
  background-color: white
}

.grid3Child1 {
  grid-area: ch1;
  color: black
}

.pCenter {
  font-size: 75px;
  word-spacing: 15px;
}

.gridI {
  display: grid;
  align-items: center;
  justify-items: center;
  grid-template-columns: 1fr 4fr 1fr;
  grid-template-rows: 200px;
  grid-template-areas: 'ch1 ch2 ch3';
  background-color: transparent
}

.gridIChild1 {
  grid-area: ch1;
  color: white;
}

.gridIChild2 {
  grid-area: ch2;
  color: white;
  text-align: center;
  line-height: 30px
}

.gridIChild3 {
  grid-area: ch3;
  color: white;
}

.grid7 {
  display: grid;
  align-items: center;
  justify-items: center;
  grid-template-columns: 1fr 4fr 1fr;
  grid-template-rows: 200px;
  grid-template-areas: 'ch1 ch2 ch3';
  background-color: transparent
}

.grid7Child1 {
  grid-area: ch1;
  color: white;
}

.grid7Child2 {
  grid-area: ch2;
  color: white;
  text-align: center;
  line-height: 30px
}

.grid7Child3 {
  grid-area: ch3;
  color: white;
}

.pCenter1 {
  margin-top: 50px;
  font-size: 30px;
  letter-spacing: 10.5px;
}

.pCenter2 {
  line-height: 24px;
  font-size: 16px;
  padding: 15px 60px 0px 60px;
  margin-bottom: 15px;
  letter-spacing: 0
}

.flex1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  top: 72%;
  color: white;
  opacity: 0.0
}

.flex1Child1 {
  flex-grow: 1;
  margin-bottom: 40px;
}

.flex1Child2 {
  flex-grow: 1;
  font-size: 25px;
  text-decoration: none;
  color: white;
  text-align: center;
}

.flex2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  top: 72%;
  color: white;
  opacity: 0.0
}

.flex2Child1 {
  flex-grow: 1;
  margin-bottom: 40px
}

.flex2Child2 {
  flex-grow: 1;
  font-size: 25px;
  text-decoration: none;
  color: white;
  text-align: center
}

.flex3 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  top: 72%;
  color: white;
  opacity: 0.0
}

.flex3Child1 {
  flex-grow: 1;
  margin-bottom: 40px
}

.flex3Child2 {
  flex-grow: 1;
  font-size: 25px;
  text-decoration: none;
  color: white;
  text-align: center
}

.flex4 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  top: 72%;
  color: white;
  opacity: 0.0
}

.flex4Child1 {
  flex-grow: 1;
  margin-bottom: 40px
}

.flex4Child2 {
  flex-grow: 1;
  font-size: 25px;
  text-decoration: none;
  color: white;
  text-align: center
}

.flex5 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  top: 72%;
  color: white;
  opacity: 0.0
}

.flex5Child1 {
  flex-grow: 1;
  margin-bottom: 40px
}

.flex5Child2 {
  flex-grow: 1;
  font-size: 25px;
  text-decoration: none;
  color: white;
  text-align: center
}

.grid4 {
  display: grid;
  align-items: center;
  justify-items: center;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: 'ch1 ch2';
  gap: 200px
}

.grid4Child1 {
  grid-area: ch1;
  font-size: 25px;
  text-decoration: none;
  color: white;
}

.grid4Child2 {
  grid-area: ch2;
  font-size: 25px;
  text-decoration: none;
  color: white
}

.grid5 {
  display: grid;
  align-items: center;
  justify-items: center;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: 'ch1 ch2';
  gap: 200px
}

.grid5Child1 {
  grid-area: ch1;
  font-size: 25px;
  text-decoration: none;
  color: white
}

.grid5Child2 {
  grid-area: ch2;
  font-size: 25px;
  text-decoration: none;
  color: white
}

.grid6 {
  display: grid;
  align-items: center;
  justify-items: center;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: 'ch1 ch2';
  gap: 200px
}

.grid6Child1 {
  grid-area: ch1;
  font-size: 25px;
  text-decoration: none;
  color: white
}

.grid6Child2 {
  grid-area: ch2;
  font-size: 25px;
  text-decoration: none;
  color: white
}

.elementHeader {
  margin-top: 75px;
  width: 100%;
}

.elementHeader4 {
  margin-top: 75px;
  width: 100%;
  height: 548px
}

.elementHeader1 {
  object-fit: cover;
  width: 100%;
  height: 40vw;
  margin-top: 75px;
  z-index: -1
}

.elementHeader2 {
  width: 100%
}

.elementHeader3 {
  object-fit: cover;
  width: 100%;
  height: 40vw;
  z-index: -1
}

.flex7 {
  display: flex;
  align-items: center;
  justify-items: center;
  background-color: transparent
}

/* .flex7Child1{flex-grow: 1;color:white;} */
.flex7Child2 {
  flex-grow: 4;
  color: white;
  text-align: center;
  line-height: 40px
}

/* .flex7Child3{flex-grow: 1;color:white;} */
.pCenter1 {
  font-size: 33px;
  letter-spacing: 10.5px;
}

.pCenter2 {
  line-height: 24px;
  font-size: 16px;
  padding: 15px 60px 0px 60px;
  margin-bottom: 15px;
  letter-spacing: 0
}

.flex9 {
  display: flex;
  align-items: center;
  justify-items: center;
  background-color: transparent
}

/* .flex9Child1{flex-grow: 1;color:white;} */
.flex9Child2 {
  flex-grow: 1;
  color: white;
  text-align: center;
  line-height: 40px
}

/* .flex9Child3{flex-grow: 1;color:white;} */
.pCenter3 {
  font-size: 30px;
  letter-spacing: 0;
}

.pCenter4 {
  line-height: 24px;
  font-size: 16px;
  padding: 15px 60px 0px 60px;
  margin-bottom: 15px;
  letter-spacing: 0
}

.gridLink {
  display: grid;
  align-items: center;
  justify-items: center;
  grid-template-columns: 1fr;
  grid-template-areas: 'ch1';
}

.gridLink1 {
  display: grid;
  align-items: center;
  justify-items: center;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: 'ch1 ch2';
}

.gridLinkChild1 {
  border: 3px solid white;
  padding: 5px 15px 10px 15px;
  grid-area: ch1;
  text-decoration: none;
  color: white;
  font-size: 13px;
}

.gridLinkChild2 {
  border: 3px solid white;
  padding: 3px 15px 3px 15px;
  grid-area: ch1;
  text-decoration: none;
  color: white;
  font-size: 13px;
}

.pCenter01 {
  font-size: 24px;
  padding: 50px;
}

.pCenter016 {
  font-size: 30px;
  letter-spacing: 0;
  padding: 50px;
  text-align: center
}

.pCenter017 {
  font-size: 15px;
}

/* .pCenter1{font-size: 30px;letter-spacing:0; */
/* } */
.blockTem {
  display: block;
  padding: 100px;
  color: white
}

.flexF1 {
  display: flex;
  margin-bottom: 20px
}

.gridG1 {
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-template-areas: ch1 ch2;
  gap: 1em
}

.gridG2 {
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-template-areas: ch1 ch2;
  gap: 1em
}

.gridG3 {
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-template-areas: ch1 ch2;
  gap: 1em
}

.blockB1 {
  display: block;
  grid-area: ch1;
  text-align: right
}

.blockB2 {
  display: flex;
  grid-area: ch2;
  flex-direction: column
}

.blockB3 {
  display: block;
  grid-area: ch1;
  text-align: right
}

.blockB4 {
  display: flex;
  grid-area: ch2;
  flex-direction: column
}

.blockB5 {
  display: block;
  grid-area: ch1;
  text-align: right
}

.blockB6 {
  display: flex;
  grid-area: ch2;
  flex-direction: column
}

.flexF2 {
  display: flex;
}

.flexF12 {
  display: flex;
  margin-bottom: 20px
}

.gridG1 {
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-template-areas: "ch1 ch2";
  gap: 1em
}

.gridG2 {
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-template-areas: "ch1 ch2";
  gap: 1em
}

.gridG3 {
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-template-areas: "ch1 ch2";
  gap: 1em
}

.blockB1 {
  display: block;
  grid-area: ch1;
  text-align: right
}

.blockB2 {
  display: flex;
  grid-area: ch2;
  flex-direction: column
}

.blockB3 {
  display: block;
  grid-area: ch1;
  text-align: right
}

.blockB4 {
  display: flex;
  grid-area: ch2;
  flex-direction: column
}

.blockB5 {
  display: block;
  grid-area: ch1;
  text-align: right
}

.blockB6 {
  display: flex;
  grid-area: ch2;
  flex-direction: column
}

.flexF22 {
  display: flex;
  margin-bottom: 20px
}

.imgAudio1 {
  font-size: 35px;
  cursor: pointer
}

.imgAudio2 {
  display: none;
  font-size: 35px;
  cursor: pointer
}

.inputAudio {
  height: 4px;
  font-size: small;
  color: #fff;
  background-color: #fff;
  cursor: pointer;
  z-index: 100
}

progress {
  height: 4px
}

footer {
  margin-top: 100px
}

.myStyle4 {
  display: none;
  opacity: 0.5;
}

.myStyleY-a {
  color: orangered;
  text-decoration: none
}

.myStyleY-b {
  color: white;
  text-decoration: none
}

.myStyle2-a {
  grid-area: cp;
  background-image: url(/public/images/PROJETS2.png);
  background-position: center;
  opacity: 0.5
}


.myStyle2-b {
  grid-area: cp;
  background-image: url(/public/images/PROJETS2.png);
  background-position: center;
}


.myStyle3-a {
  grid-area: ch3;
  background-image: url(/public/images/HISTOIRES.png);
  background-position: center;
  opacity: 0.5
}

.myStyle3-b {
  grid-area: ch3;
  background-image: url(/public/images/HISTOIRES.png);
  background-position: center;
}

.myStyle5-a {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  top: 72%;
  color: white;
  opacity: 1
}


.myStyle5-b {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  top: 72%;
  color: white;
  opacity: 0.0
}



.myStyle6-a {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  top: 72%;
  color: white;
  opacity: 1
}



.myStyle6-b {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  top: 72%;
  color: white;
  opacity: 0.0
}

.myStyle7-a {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  top: 72%;
  color: white;
  opacity: 1
}



.myStyle7-b {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  top: 72%;
  color: white;
  opacity: 0.0
}

;







.myStyle8-a {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  top: 72%;
  color: white;
  opacity: 1
}



.myStyle8-b {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  top: 72%;
  color: white;
  opacity: 0.0
}


.bt1-a {
  border: 3px solid orangered;
  padding: 5px 15px 10px 15px;
  grid-area: ch1;
  text-decoration: none;
  color: white;
  background-color: orangered
}


.bt1-b {
  border: 3px solid white;
  padding: 5px 15px 10px 15px;
  grid-area: ch1;
  text-decoration: none;
  color: white;
}

.bt2-a {
  background-color: orangered;
  border: 3px solid orangered;
  padding: 3px 15px 3px 15px;
  grid-area: ch1;
  text-decoration: none;
  color: white;
}

.bt2-b {
  border: 3px solid white;
  padding: 3px 15px 3px 15px;
  grid-area: ch1;
  text-decoration: none;
  color: white;
}



.bt1-1a {
  border: 3px solid orangered;
  padding: 3px 15px 3px 15px;
  grid-area: --var(ch1);
  text-decoration: none;
  color: white;
  background-color: orangered;
}

.bt1-1b {
  border: 3px solid white;
  padding: 3px 15px 3px 15px;
  grid-area: --var(ch2);
  text-decoration: none;
  color: white;
}

.bt2-2a {
  border: 3px solid orangered;
  padding: 3px 15px 3px 15px;
  grid-area: --var(ch1);
  text-decoration: none;
  color: white;
  background-color: orangered;
}

.bt2-2b {
  border: 3px solid white;
  padding: 3px 15px 3px 15px;
  grid-area: --var(ch2);
  text-decoration: none;
  color: white;
}




#hover-grid:hover {
  opacity: 0.3;
}

#hover-grid-link-child1:hover {
  background-color: orangered;
  border: 3px solid orangered;
}

.hover-grid-link-child2:hover {
  background-color: orangered;
  border: 3px solid orangered;
}

.hover-link:hover {
  color: orangered;
  display: flex;
}

.hover-grid-link:hover{opacity: 0.5;}
.click-color {
  color: orangered;
}

/* font-family:wfont_b0dbc0_13ffb9ae83134f7a80c83fbb7ca34e8f,wf_13ffb9ae83134f7a80c83fbb7,orig_poison_regular; */

.hove-pp:hover {
  color: rgb(238, 136, 42);
}

.hove-range:hover {
  background-color: rgb(238, 136, 42);
}

.play-style1 {
  display: block;
  font-size: 35px;
  cursor: pointer
}

.pause-style1 {
  display: none;
  font-size: 35px;
  cursor: pointer;
}

.play-style2 {
  display: none;
  font-size: 35px;
  cursor: pointer
}

.pause-style2 {
  display: block;
  font-size: 35px;
  cursor: pointer
}

.play-style1:hover {
  color: rgb(238, 136, 42)
}

.pause-style1:hover {
  color: rgb(238, 136, 42);
}

.play-style2:hover {
  color: rgb(238, 136, 42)
}

.pause-style2:hover {
  color: rgb(238, 136, 42)
}


.person-slider {
  background-color: rgb(238, 136, 42);
}

.fl-inp {
  flex-grow: 1
}

.f1 {
  display: block;
  width: 0%;
  height: 2px;
  background-color: transparent;
  position: fixed;
  top: 0px;
  right: 0px;
  left: 0px;
  z-index: 600;
  animation: progress 2s ease-in-out 1 normal
}

@keyframes progress {
  0% {
    width: 0%;
    background-color: #fff
  }

  5% {
    width: 5%;
    background-color: #fff
  }

  10% {
    width: 10%;
    background-color: #fff
  }

  15% {
    width: 15%;
    background-color: #fff
  }

  20% {
    width: 20%;
    background-color: #fff
  }

  25% {
    width: 25%;
    background-color: #fff
  }

  30% {
    width: 30%;
    background-color: #fff
  }

  35% {
    width: 35%;
    background-color: #fff
  }

  40% {
    width: 40%;
    background-color: #fff
  }

  45% {
    width: 45%;
    background-color: #fff
  }

  50% {
    width: 50%;
    background-color: #fff
  }

  55% {
    width: 55%;
    background-color: #fff
  }

  60% {
    width: 60%;
    background-color: #fff
  }

  65% {
    width: 65%;
    background-color: #fff
  }

  70% {
    width: 70%;
    background-color: #fff
  }

  75% {
    width: 75%;
    background-color: #fff
  }

  80% {
    width: 80%;
    background-color: #fff
  }

  85% {
    width: 85%;
    background-color: #fff
  }

  90% {
    width: 90%;
    background-color: #fff
  }

  95% {
    width: 95%;
    background-color: #fff
  }

  100% {
    width: 100%;
    background-color: #fff
  }

}

.f2 {
  display: none;
}

.f2-a {
  display: grid;
  width: 40px;
  /* height: 55px; */
  padding: 5px 0px 5px 0px;
  position: fixed;
  top: 85%;
  right: 2.0%;
  border-radius: 75px;
  text-align: center;
  background-color: #fff;
  box-shadow: 0px 5px 30px rgb(0 0 0 / 8%);
  z-Index: 600;
}

.f2-b {
  display: none;
  width: 40px;
  color: black;
  /* height: 55px; */
  padding: 5px 0px 5px 0px;
  position: fixed;
  top: 85%;
  right: 2.0%;
  border-radius: 75px;
  text-align: center;
  background-color: #fff;
  box-shadow: 0px 5px 30px rgb(0 0 0 / 8%);
  z-index: 600;
}

.f2-c {
  display: grid;
  width: 40px;
  color: white;
  /* height: 55px; */
  padding: 5px 0px 5px 0px;
  position: fixed;
  top: 85%;
  right: 2.0%;
  border-radius: 75px;
  text-align: center;
  background-color: #ff4e4e;
  box-shadow: 0px 5px 30px rgb(0 0 0 / 8%);
  z-index: 600;
}

.opace-no {
  opacity: 0;
}

.opace-no-yes {
  opacity: 0.5;
}

.opace-yes {
  animation: opaceYes 2s 1 ease-in normal;
  /* opacity: 1; */
}

@keyframes opaceYes {
  0% {
    opacity: 0;
  }

  20% {
    opacity: 0.2;
  }

  40% {
    opacity: 0.4;
  }

  60% {
    opacity: 0.6;
  }

  80% {
    opacity: 0.8;
  }

  100% {
    opacity: 1;
  }
}



.eH {
  margin-top: 75px;
  width: 100%;
  animation: opaceYes 2s 1 ease-in normal;
}

.eH4 {
  margin-top: 75px;
  width: 100%;
  height: 548px;
  animation: opaceYes 2s 1 ease-in normal;
}

.eH1 {
  object-fit: cover;
  width: 100%;
  height: 40vw;
  margin-top: 75px;
  z-index: -1;
  animation: opaceYes 2s 1 ease-in normal;
}

.eH2 {
  width: 100%;
  animation: opaceYes 2s 1 ease-in normal;
}

.eH3 {
  object-fit: cover;
  width: 100%;
  height: 40vw;
  z-index: -1;
  animation: opaceYes 2s 1 ease-in normal;
}

.g9C1 {
  grid-area: ch1;
  background-image: url(/public/images/rs-prod5.png);
  background-position: center;
  background-size: cover;
  animation: opaceYes 2s 1 ease-in normal;
}

.g9C2 {
  grid-area: ch2;
  background-image: url(/public/images/CONTENUESITE.png);
  background-position: center;
  background-size: cover;
  animation: opaceYes 2s 1 ease-in normal;
}


.g1DC1 {
  grid-area: ch1;
  background-image: url(/public/images/rs-prod2.png);
  background-position: center;
  background-size: cover;
  animation: opaceYes 2s 1 ease-in normal;
}

.g1DC4 {
  grid-area: ch4;
  background-image: url(/public/images/rs-prod3.png);
  background-position: center;
  background-size: cover;
  animation: opaceYes 2s 1 ease-in normal;
}

.g1DC5 {
  grid-area: ch5;
  background-image: url(/public/images/rs-prod4.png);
  background-position: center;
  background-size: cover;
  animation: opaceYes 2s 1 ease-in normal;
}


.g8AC1 {
  /* grid-area: ch1; */
  /* background-image: url(/public/images/new_1.jpg);
  background-position: center;
  background-size: cover; */
  animation: opaceYes 2s 1 ease-in normal;
}

.g8AC2 {
  grid-area: ch2;
  background-image: url(/public/images/new_2.jpg);
  background-position: center;
  background-size: cover;
  animation: opaceYes 2s 1 ease-in normal;
}



.g3DC1 {
  grid-area: ch1;
  background-image: url(/public/images/CONTENU_DU_SITE22.png);
  background-position: center;
  background-size: cover;
  animation: opaceYes 2s 1 ease-in normal;
}

.g3DC4 {
  grid-area: ch4;
  background-image: url(/public/images/CONTENU_DU_SITE21.png);
  background-position: center;
  background-size: cover;
  animation: opaceYes 2s 1 ease-in normal;
}


.g2BC1 {
  grid-area: ch1;
  background-image: url(/public/images/CONTENU_DU_SITE16.png);
  background-position: center;
  background-size: cover;
  animation: opaceYes 2s 1 ease-in normal;
}

.g2BC4 {
  grid-area: ch4;
  background-image: url(/public/images/CONTENU_DU_SITE14.png);
  background-position: center;
  background-size: cover;
  animation: opaceYes 2s 1 ease-in normal;
}

.g2BC6 {
  grid-area: ch6;
  background-image: url(/public/images/CONTENU_DU_SITE13.png);
  background-position: center;
  background-size: cover;
}





@keyframes opaceYes {
  0% {
    opacity: 0;
  }

  20% {
    opacity: 0.2;
  }

  40% {
    opacity: 0.4;
  }

  60% {
    opacity: 0.6;
  }

  80% {
    opacity: 0.8;
  }

  100% {
    opacity: 1;
  }
}

.form-control-rs {
  flex-grow: 1;
  border: none;
  padding: 20px 20px 20px 20px;
  max-width: fit-content;
  height: auto;
}

.form-control-rs {
  flex-grow: 1;
  border: none;
  padding: 20px 20px 20px 20px;
  max-width: fit-content;
  height: auto;
}

.div-form {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: -50px;
}

.div-form-ch1 {
  flex-grow: 2.5;
}

.div-form-ch2 {
  flex-grow: 1;
}

.div-form-ch3 {
  flex-grow: 2.5;
}

.div-form-block {
  display: block;
}

.div-form-block-flex {
  display: flex;
}

.div-form-block-flex1 {
  flex-grow: 3;
}

.div-form-block-flex2 {
  flex-grow: 1;
}









@media screen and (max-width: 1060px) {
  .elementHeader {
  margin-top: 75px;
  width: 100%;
}
  .pCenter1 {
    margin-top: 20px;
    font-size: 20px;
    letter-spacing: 10.5px;
  }

  .pCenter01 {
    font-size: 20px;
    padding: 50px;
  }
  
  .pCenter2 {
    line-height: 24px;
    font-size: 16px;
    padding: 15px 60px 0px 60px;
    margin-bottom: 15px;
    letter-spacing: 0
  }
  
  .grid8A {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 25px 25px 25px 25px;
    grid-template-rows: 730px 410px;
    grid-template-areas: 'ch1 ch2' 'ch3 ch4';
    gap: 0;
  }
  
}

@media screen and (max-width: 998px) {
  .elementHeader {
  margin-top: 75px;
  width: 100%;
}
  .pCenter1 {
    margin-top: 20px;
    font-size: 18px;
    letter-spacing: 8.5px;
  }

  .pCenter01 {
    font-size: 20px;
    padding: 50px;
  }
  
  .pCenter2 {
    line-height: 20px;
    font-size: 16px;
    padding: 15px 30px 0px 30px;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 15px;
    letter-spacing: 0
  }
}


@media screen and (max-width: 700px) {
  .elementHeader {
  margin-top: 75px;
  width: 100%;
}
  .pCenter1 {
    margin-top: 70px;
    font-size: 18px;
    letter-spacing: 8.5px;
    margin-left: -50px;
    margin-right: -50px;
  }

  .pCenter01 {
    font-size: 18px;
    padding: 50px;
  }
  
  .pCenter2 {
    line-height: 20px;
    font-size: 16px;
    padding: 15px 30px 0px 30px;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 15px;
    letter-spacing: 0;
    text-align: left;
  }
}


@media screen and (max-width: 624px) {
  .fz{display:block;font-size: 35px; cursor: pointer;}
  .header {
    display: grid;
    background-color: transparent;
    padding: 15px 15px 15px 15px;
    grid-template-rows: 39px;
    grid-template-columns: 11fr 1fr;
    grid-template-areas: "ch3 ch2";
    position: fixed;
    top: 0px;
    right: 0px;
    left: 0px;
  }

  .headerChild1{display: none;}
  
  .headerChild2 {
    display: flex;
    align-items: center;
    justify-content: right;
    grid-area: ch2;
    /* margin-top: -6px; */
  }
  
  .headerChild3 {
    display: flex;
    align-items: center;
    justify-content: left;
    grid-area: ch3
  }

  .linkStyle1 {
    color: white;
    text-decoration: none
  }

  /* .linkStyle13 {
    display: block;
    color: white;
    text-decoration: none
  } */
  
  
  /* .linkStyle2 {
    display: block;
    color: white;
    text-decoration: none;
    margin-top: 16px;
    margin-left: -21px;
  } */

  .hover-opacity-1{
    /* margin-top: 12px; */
    max-width:40px;height:40px;border:1px solid transparent;
    border-radius:40px;border-width:2px 2px 2px 2px;border-color:white
}

.hover-opacity-1:hover{opacity: 0.7;}

}
@media screen and (max-width: 600px) {
  .elementHeader {
    margin-top: 75px;
    width: 100%;
  }
  .pCenter1 {
    margin-top: 60px;
    font-size: 17px;
    letter-spacing: 3.5px;
    margin-left: -70px;
    margin-right: -70px;
  }

  .pCenter01 {
    font-size: 17px;
    padding: 50px;
  }
  
  .pCenter2 {
    line-height: 20px;
    font-size: 16px;
    padding: 15px 30px 0px 30px;
    margin-left: -70px;
    margin-right: -70px;
    margin-bottom: 15px;
    letter-spacing: 0;
    text-align: left;
  }
}




@media screen and (max-width: 460px) {
  .text-size{
    text-align: start;
  }
  .elementHeader {
  margin-top: 75px;
  width: 100%;
}
  .pCenter1 {
    margin-top: 70px;
    font-size: 17px;
    letter-spacing: 1.5px;
    margin-left: -70px;
    margin-right: -70px;
  }

  .pCenter01 {
    font-size: 17px;
    padding: 50px;
  }
  
  .pCenter2 {
    line-height: 20px;
    font-size: 16px;
    padding: 15px 30px 0px 30px;
    /* margin-left: -70px;
    margin-right: -70px; */
    margin-bottom: 15px;
    letter-spacing: 0;
    text-align: left;
  }
}


@media screen and (max-width: 430px) {
  .elementHeader {
  margin-top: 75px;
  width: 100%;
}
  .pCenter1 {
    margin-top: 100px;
    font-size: 17px;
    letter-spacing: 1.5px;
    margin-left: -30px;
    margin-right: -30px;
  }

  .pCenter01 {
    padding: 50px;
    font-size: 17px;
  }
  
  .pCenter2 {
    line-height: 20px;
    font-size: 16px;
    padding: 15px 30px 0px 30px;
    margin-left: -30px;
    margin-right: -30px;
    margin-bottom: 15px;
    letter-spacing: 0;
    text-align: left;
  }
}



@media screen and (max-width: 360px) {
  .elementHeader {
  margin-top: 75px;
  width: 100%;
}
  .pCenter1 {
    margin-top: 100px;
    font-size: 17px;
    letter-spacing: 1.5px;
    margin-left: -30px;
    margin-right: -30px;
  }

  .pCenter01 {
    padding: 30px;
    font-size: 17px;
  }
  
  .pCenter2 {
    line-height: 20px;
    font-size: 16px;
    padding: 15px 30px 0px 30px;
    margin-left: -30px;
    margin-right: -30px;
    margin-bottom: 15px;
    letter-spacing: 0;
    text-align: left;
  }
}


@media screen and (max-width: 360px) {
  .pCenter1 {
    margin-top: 100px;
    margin-left: -60px;
    margin-right: -60px;
    font-size: 16px;
    letter-spacing: 3.5px;
  }

  .pCenter01 {
    font-size: 16px;
    padding: 50px;
  }
  
  .pCenter2 {
    line-height: 20px;
    font-size: 16px;
    padding: 15px 50px 0px 50px;
    margin-left: -70px;
    margin-right: -70px;
    margin-bottom: 15px;
    letter-spacing: 0;
    text-align: left;
  }
}